require('lib/misans-100-thin.min.css');
require('lib/misans-200-extralight.min.css');
require('lib/misans-300-light.min.css');
require('lib/misans-400-normal.min.css');
require('lib/misans-400-regular.min.css');
require('lib/misans-500-medium.min.css');
require('lib/misans-600-demibold.min.css');
require('lib/misans-600-semibold.min.css');
require('lib/misans-700-bold.min.css');
require('lib/misans-900-heavy.min.css');
